import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Presenta un capell de fins a 40 cm de diàmetre de forma convexa, carnós, amb el marge girat interiorment i després ondulat. És com a vellutat, llis i de color blanc brut o blanc grisenc però mai rogenc ni rosa. Té tubs llargs, rodejant el peu, grocs o grocs verdosos tornant blaus quan es toquen amb els dits. Els porus són més o manco rodons, grocs als exemplars joves i ataronjats o vermells quan són madurs. El peu és esfèric, gruixat, de color groc o groc ataronjat a la part superior i vermell porpra a la part inferior. Està recobert per un reticle poligonal de malla ample de colors vermell, rosa i blanquinós. Les espores són marró oliva en massa, fusiformes, d’11-16 x 5-7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      